export default {
    // 本地环境
    // baseUrl:'http://dev.test.com.cn:9501',
    // apiUrl:'http://dev.test.com.cn:9501/Api',
    // serviceUrl:'http://api.clb.org.cn/Service',
    // uploadUrl:'http://dev.test.com.cn:9501/Api/Upload/upload',
    // assetsUrl:'http://127.0.0.1:9501',
    
    // baseUrl:'http://127.0.0.1:9501',
    // apiUrl:'http://127.0.0.1:9501/Api',
    // serviceUrl:'http://127.0.0.1:9501/Service',
    // uploadUrl:'http://127.0.0.1:9501/Api/Upload/upload',
    // assetsUrl:'http://127.0.0.1:9501',

    // 开发环境
    // baseUrl:'http://api.test.clb.org.cn',
    // apiUrl:'http://api.test.clb.org.cn/Api',
    // serviceUrl:'http://api.test.clb.org.cn/Service',
    // uploadUrl:'http://api.test.clb.org.cn/Api/Upload/upload',
    // assetsUrl:'http://api.test.clb.org.cn/static',
    
    // 正式环境npm
    baseUrl:'http://api.clb.org.cn',
    apiUrl:'http://api.clb.org.cn/Api',
    serviceUrl:'http://api.clb.org.cn/Service',
    uploadUrl:'http://api.clb.org.cn/Api/Upload/upload',
    assetsUrl:'http://api.clb.org.cn/static',
    
    // baseUrl:'http://api.test.clb.miihe.com',
    // apiUrl:'http://api.test.clb.miihe.com/Api',
    // serviceUrl:'http://api.test.clb.miihe.com/Service',
    // uploadUrl:'http://api.test.clb.miihe.com/Api/Upload/upload',
    // assetsUrl:'http://api.test.clb.org.cn/static',
    // assetsUrl:'http://api.test.clb.miihe.com/static',

    // 正式环境npm
    // baseUrl:'http://api.clb.org.cn',
    // apiUrl:'http://api.clb.org.cn/Api',
    // serviceUrl:'http://api.clb.org.cn/Service',
    // uploadUrl:'http://api.clb.org.cn/Api/Upload/upload',
    // assetsUrl:'http://api.clb.org.cn/static',

    secret:'zhaobiaowang!@#',
}
