<template>
	<m-main-layout>
		<bread></bread>
		<div class="cl margin-top-15">
			<banner akey="notice_top_banner" width="1200px" height="172px"></banner>
<!--      <div class="z border-w-1 hide border-dark-6" style="width: 198px;height: 344px;padding:10px 20px !important;">-->
<!--          <div class="cl">-->
<!--              <div class="z red f18 f-sy" style="margin-top: 3px;">招标推荐</div>-->
<!--              <div class="y dark-2 f14 f-sy hand" style="margin-top: 6px;" @click="$router.push({path:'/tjbids'})">更多>></div>-->
<!--          </div>-->
<!--          <zbscroll></zbscroll>-->
<!--      </div>-->
<!--      <div class="y">-->
<!--          <banner akey="index_banner" width="1200px" height="172px"></banner>-->
<!--&lt;!&ndash;                <img src="/images/banner1.jpg" height="344"/>&ndash;&gt;-->
<!--      </div>-->
		</div>
		<div class="cl">
			<div class="z hide" style="width: 883px;">
				<el-form inline v-if="type === 'notice' || type === 'cold'">
					<el-form-item label="行业">
						<type-select v-model="searchForm.type_industry_id" url="/Type/listAll" :can-add="false" placeholder="所有行业" :data="{akey:'industry',orderBy:'sort',sortBy:'asc',state: 1}" style="width: 180px;"></type-select>
					</el-form-item>
					<el-form-item label="服务">
						<type-select v-model="searchForm.type_fuwu_id" url="/Type/listAll" :can-add="false" placeholder="所有服务" :data="{akey:'fuwu',orderBy:'sort',sortBy:'asc',state: 1}" style="width: 180px;"></type-select>
					</el-form-item>
					<el-form-item label="区域">
						<type-select v-model="searchForm.province_code" url="/Area/listAll" :can-add="false" placeholder="所有区域" :data="{pid:['1','<='],orderBy:'code',sortBy:'asc',state: 1}" style="width: 180px;" val-key="code"></type-select>
					</el-form-item>
					<el-button type="primary" @click="toSearch">查询</el-button>
					<el-button @click="toReset">重置</el-button>
				</el-form>
				<div class="margin-20 padding-bottom-20 border-bottom">
					<el-row>
						<el-col :span="9" class="f18 dark-2 f-sy">项目名称</el-col>
						<el-col :span="5" class="text-center f18 dark-2 f-sy">所属行业</el-col>
<!--						<el-col v-if="type!='coldNeed'" :span="5" class="text-center f18 dark-2 f-sy">直采/代理</el-col>-->
<!--						<el-col v-if="type=='coldNeed'" :span="5" class="text-center f18 dark-2 f-sy">需求类型</el-col>-->
						<el-col :span="5" class="text-center f18 dark-2 f-sy">发布时间
							<span class="sort" @click="changeSort('create_time')"><i class="el-icon-sort-down" :class="{on:sort=='create_time' && sortBy=='desc'}"></i><i class="el-icon-sort-up
"  :class="{on:sort=='create_time' && sortBy=='asc'}"></i></span>
</el-col>
						<el-col :span="5" class="text-center f18 dark-2 f-sy">截止时间<span class="sort" @click="changeSort('end_time')"><i class="el-icon-sort-down" :class="{on:sort=='end_time' && sortBy=='desc'}"></i><i class="el-icon-sort-up
"  :class="{on:sort=='end_time' && sortBy=='asc'}"></i></span></el-col>
					</el-row>
					<div class="cl margin-top"></div>
					<el-row class="red-hover padding-top-bottom-11 dark-2" v-for="(item,index) in list" :key="index">
						<a :href="'/bids/detail/'+item.id" target="_blank" rel="opener">
							<el-col :span="9" class="f16 f-sy hand text-line-1" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}" style="height:22px;overflow:hidden;line-height:22px;">
								{{ item.title }}
							</el-col>
							<el-col :span="5" class="text-center f16 f-sy">
								<small-tag :label="item.industry.name" color="red"></small-tag>
							</el-col>
								<!--							<el-col :span="5" class="text-center f-sy" style="height: 22px;" v-if="type!=='coldNeed'">-->
<!--								<template v-if="item.show_zd === 1">-->
<!--									<small-tag :label="item.zd.type_key === 'daili' ? '代理' : '直采'" :color="item.zd.type_key === 'daili' ? 'orange' : 'red'" v-if="item.zd"></small-tag>-->
<!--								</template>-->
<!--								<div class="cl" style="height: 2px;" v-else></div>-->
<!--							</el-col>-->
<!--							<el-col v-if="type=='coldNeed'" :span="5" class="text-center f16 f-sy">-->
<!--								<small-tag :label="item.demand == null ? '' : item.demand.name" color="orange"></small-tag>-->
<!--							</el-col>-->
							<el-col :span="5" class="text-center f16 f-sy">{{ $moment(item.create_time).format('MM-DD') }}</el-col>
							<el-col :span="5" class="text-center f16 f-sy">
								{{ item.end_time ? $moment(item.end_time).format('MM-DD') : '-' }}
							</el-col>
						</a>
					</el-row>
				</div>
			</div>
			<div class="y hide" style="width: 317px;">
				<div class="bg-gray-4 padding-15 cl">
					<img src="/images/icon_member.png" class="z"/>
					<div class="z f18 margin-left dark-2 f-lq">最新加入</div>
					<div class="cl"></div>
					<div class="margin-l-r-35 margin-top">
						<div class="text-line-1" v-for="(item,index) in companyList" :key="index" style="line-height: 44px;">
							<a class="f16" @click="toOutUrl(item.out_url)">{{ item.title }}</a></div>
					</div>
				</div>
				<div class="">
					<!--                    <img src="/images/banner6.jpg"/>-->
					<banner akey="side_banner1" width="317px" height="247px"></banner>
				</div>
				<div class="bg-gray-4 padding-15 cl padding-top-30" style="height: 378px;">
					<ccgq></ccgq>
				</div>
			</div>
		</div>
		<div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
			<el-pagination
				background
				@current-change="pageChange"
				:current-page="searchForm.page"
				:page-size="pageSize"
				layout="prev, pager, next, jumper"
				:total="totalCount">
			</el-pagination>
		</div>
	</m-main-layout>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			searchForm: {
				page: 1,
				province_code: '',
				type_industry_id: '',
				type_fuwu_id: ''
			},
			type: '',
			second_type: "",
			pageSize: 20,
			totalCount: 0,
			list: [],
			companyList: [],
			sort:"create_time",
			sortBy:"desc"
		}
	},
	created() {
		this.type = this.$route.name
		console.log(this.type);
		this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
		this.searchForm.province_code = this.$route.query.province_code ? this.$route.query.province_code : '';
		this.searchForm.type_fuwu_id = this.$route.query.type_fuwu_id ? this.$route.query.type_fuwu_id : '';
		this.searchForm.type_industry_id = this.$route.query.type_industry_id ? this.$route.query.type_industry_id : '';
	},
	mounted() {
		this.getList();
		this.getCompanyList();
	},
	methods: {
		changeSort(type){
			if(this.sort==type){
				this.sortBy=this.sortBy=='desc'?'asc':'desc'
			}
			else{
				this.sort=type;
				this.sortBy='desc';
			}
			this.searchForm.page=1;
			this.getList()
		},
		toSearch() {
			// this.getList();
			this.searchForm.page = 1;
			this.$router.replace({query: {...this.searchForm}});
		},
		toReset() {
			this.searchForm.type_industry_id = '';
			this.searchForm.province_code = '';
			this.searchForm.type_fuwu_id = '';
			this.searchForm.page = 1;
			this.$router.replace({query: {...this.searchForm}});
			// this.getList();
		},
		pageChange(e) {
			this.searchForm.page = e;
			this.$router.replace({query: {...this.searchForm}});
		},
		getList() {
			let _this = this;
			// let params = {...this.searchForm, type: this.type, pageSize: this.pageSize, state: 1, orderBy: 'sort desc,create_time', sortBy: 'desc', is_tuijian: 0}
			let params = {...this.searchForm, pageSize: this.pageSize, state: 1, orderBy: 'sort '+this.sortBy+','+this.sort, sortBy: this.sortBy}
			// if(this.type=='coldBid'||this.type=='coldNeed'){
			//   params.type='coldChain';
			//   params.second_type=this.type
			// }
			if(this.type === 'notice'){
				params.new_type = 'notice';
			}else if(this.type === 'cold'){
				params.new_type = 'cold';
			}
			
			this.$http.httpPost({
				url: '/Bids/listPage',
				datas: params,
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		getCompanyList() {
			let _this = this;
			this.$http.httpPost({
				url: '/Company/listPage',
				datas: {state: 1, page: 1, pageSize: 6, orderBy: 'sort', sortBy: 'asc', type: 'vip'},
				success(res) {
					_this.companyList = res.list;
				}
			})
		},
		toDetail(id) {
			window.open('/bids/detail/' + id, '_blank')
		},
		toOutUrl(out_url) {
			if (out_url) {
				let _url = out_url.indexOf('http') >= 0 ? out_url : 'http://' + out_url;
				
				window.open(_url, '_blank');
			}
		}
	}
}
</script>
<style scoped>
	.sort{
		cursor: pointer;
	}
	.sort i{
		width:10px;
	}
	.sort .on{
		color:#2376ff ;
	}
</style>