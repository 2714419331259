<template>
	<m-main-layout>
		<bread :list="bread"></bread>
		<div class="cl margin-top-15">
			<!--            <banner :akey="type+'_top_banner'" width="1200px" height="172px" v-if="type"></banner>-->
			<!--            <div class="z border-w-1 hide border-dark-6" style="width: 198px;height: 344px;padding:10px 20px !important;">-->
			<!--                <div class="cl">-->
			<!--                    <div class="z red f18 f-sy" style="margin-top: 3px;">招标推荐</div>-->
			<!--                    <div class="y dark-2 f14 f-sy hand" style="margin-top: 6px;" @click="$router.push({path:'/tjbids'})">更多>></div>-->
			<!--                </div>-->
			<!--                <zbscroll></zbscroll>-->
			<!--            </div>-->
			<!--            <div class="y">-->
			<!--                <banner akey="index_banner" width="995px" height="344px"></banner>-->
			<!--&lt;!&ndash;                <img src="/images/banner1.jpg" height="344"/>&ndash;&gt;-->
			<!--            </div>-->
		</div>
		<div class="cl">
			<div class="z hide" style="width: 883px;">
				<el-form inline v-if="type === 'device' || type === 'medicine'">
					<el-form-item label="行业">
						<type-select v-model="searchForm.type_industry_id" url="/Type/listAll" :can-add="false" placeholder="所有行业" :data="{akey:'industry',orderBy:'sort',sortBy:'asc',state: 1}" style="width: 180px;"></type-select>
					</el-form-item>
					<el-form-item label="服务">
						<type-select v-model="searchForm.type_fuwu_id" url="/Type/listAll" :can-add="false" placeholder="所有服务" :data="{akey:'fuwu',orderBy:'sort',sortBy:'asc',state: 1}" style="width: 180px;"></type-select>
					</el-form-item>
					<el-form-item label="区域">
						{{searchForm.province_code}}
						<type-select v-model="searchForm.province_code" url="/Area/listAll" :can-add="false" placeholder="所有区域" :data="{pid:['1','<='],orderBy:'code',sortBy:'asc',state: 1}" style="width: 180px;" val-key="code"></type-select>
					</el-form-item>
					<el-button type="primary" @click="toSearch">查询</el-button>
					<el-button @click="toReset">重置</el-button>
				</el-form>
				<div class="padding-20">
					<el-row>
						<el-col :span="9" class="f18 dark-2 f-sy">项目名称</el-col>
						<el-col :span="5" class="text-center f18 dark-2 f-sy">所属行业</el-col>
						<el-col :span="5" class="text-center f18 dark-2 f-sy">发布时间<span class="sort" @click="changeSort('create_time')"><i class="el-icon-sort-down" :class="{on:sort=='create_time' && sortBy=='desc'}"></i><i class="el-icon-sort-up
"  :class="{on:sort=='create_time' && sortBy=='asc'}"></i></span></el-col>
						<el-col :span="5" class="text-center f18 dark-2 f-sy">截止时间<span class="sort" @click="changeSort('end_time')"><i class="el-icon-sort-down" :class="{on:sort=='end_time' && sortBy=='desc'}"></i><i class="el-icon-sort-up
"  :class="{on:sort=='end_time' && sortBy=='asc'}"></i></span></el-col>
					</el-row>
<!--					<template v-for="(item,index) in list">-->
<!--						<div class="padding-bottom-20 border-bottom">-->
<!--							<div class="cl margin-top"></div>-->
<!--							-->
<!--						</div>-->
<!--					</template>-->
					<el-row class="red-hover padding-top-bottom-11 dark-2" v-for="(item,index) in list" :key="index">
						<a class="cl" :href="'/bids/detail/'+item.id" target="_blank" rel="opener">
							<el-col :span="9" class="f16 f-sy hand text-line-1" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}" style="height:22px;overflow:hidden;line-height:22px;">
								{{ item.title }}
							</el-col>
							<el-col :span="5" class="text-center f16 f-sy">
								<small-tag :label="item.industry.name" color="red"></small-tag>
							</el-col>
							<el-col :span="5" class="text-center f16 f-sy">{{ $moment(item.create_time).format('MM-DD') }}</el-col>
							<el-col :span="5" class="text-center f16 f-sy">
								{{ item.end_time ? $moment(item.end_time).format('MM-DD') : '-' }}
							</el-col>
						</a>
					</el-row>
				</div>
			</div>
			<div class="y bg-gray-4 hide padding-15" style="width: 317px;" v-if="['odd','device','storage','medicine'].indexOf(type) >= 0">
				<zbtj></zbtj>
				<div class="line-1"></div>
				<ccgq></ccgq>
			</div>
		</div>
		<div class="bg-gray-4 margin-top-40 margin-bottom-40 padding margin-auto text-center" style="width:782px;height: 52px;border-radius: 26px;">
			<el-pagination
				background
				@current-change="pageChange"
				:current-page="searchForm.page"
				:page-size="searchForm.pageSize"
				layout="prev, pager, next, jumper"
				:total="totalCount">
			</el-pagination>
		</div>
		<el-dialog
			@close="onCloseQrcode"
			width="30%"
			title="支付"
			:visible.sync="showQrcode"
			append-to-body>
			<div class="text-center">
				<img :src="'data:image/jpeg;base64,'+bids.qrcode" width="166" class="border-w-1 border-dark-1"/>
				<div class="text-center f14 dark-2 margin-top-20">支付￥<b class="f18 red">{{ bids.price }}</b> ，即可查看详细内容</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showQrcode = false">关闭</el-button>
			</div>
		</el-dialog>
	</m-main-layout>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			searchForm: {
				page: 1,
				pageSize: 20,
				province_code: '',
				type_industry_id: '',
				type_fuwu_id: '',
			},
			// pageSize: 8,
			totalCount: 0,
			type: '',
			second_type: "",
			list: [],
			bread: [],
			time: null,
			showQrcode: false,
			id: '',
			bids: {
				qrcode: null,
				price: 0,
				bid_state: 2
			},
			sort:"create_time",
			sortBy:"desc"
		}
	},
	created() {
		console.log(this.type);
		this.type = this.$route.name ? this.$route.name : '';
		this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
		this.searchForm.province_code = this.$route.query.province_code ? this.$route.query.province_code : '';
		this.searchForm.type_fuwu_id = this.$route.query.type_fuwu_id ? this.$route.query.type_fuwu_id : '';
		this.searchForm.type_industry_id = this.$route.query.type_industry_id ? this.$route.query.type_industry_id : '';
	},
	mounted() {
		this.getList();
		if (this.type === 'odd') {
			this.bread = [{name: '散单平台', path: '/bids/odd'}];
		} else if (this.type === 'device') {
			this.bread = [{name: '设备采购', path: '/bids/device'}];
		} else if (this.type === 'notice') {
			this.bread = [{name: '招标公告', path: '/notice'}];
		} else if (this.type === 'storage') {
			this.bread = [{name: '仓储供给', path: '/bids/storage'}];
		}
	},
	methods: {
		changeSort(type){
			if(this.sort==type){
				this.sortBy=this.sortBy=='desc'?'asc':'desc'
			}
			else{
				this.sort=type;
				this.sortBy='desc';
			}
			this.searchForm.page=1;
			this.getList()
		},
		toSearch() {
			// this.getList();
			this.searchForm.page = 1;
			this.$router.replace({query: {...this.searchForm}});
		},
		toReset() {
			this.searchForm.type_industry_id = '';
			this.searchForm.province_code = '';
			this.searchForm.type_fuwu_id = '';
			this.searchForm.page = 1;
			this.$router.replace({query: {...this.searchForm}});
			
			// this.getList();
		},
		onCloseQrcode() {
			this.showQrcode = false;
			this.qrcode = null;
			
			this.cleanTime();
		},
		timeDown() {
			if (this.id) {
				this.time = setInterval(this.checkState, 3000)
			}
		},
		cleanTime() {
			if (this.time) {
				clearInterval(this.time);
				this.time = null
			}
		},
		checkState() {
			let _this = this;
			this.$http.httpPost({
				url: '/Bids/check',
				datas: {id: this.id},
				success(res) {
					if (res.state === 1) {
						clearInterval(_this.time);
						_this.time = null;
						
						_this.$confirm('支付成功，是否立即查看?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							let a = _this.$router.resolve({name: '/bids/detail/', params: {id: _this.id}});
							window.open(a.href, '_blank');
						}).catch(() => {
							_this.bids.qrcode = null;
							_this.bids.price = 0;
							_this.bids.bid_state = 2;
							
							_this.cleanTime();
						});
					}
				}
			})
		},
		pageChange(e) {
			this.searchForm.page = e;
			
			this.$router.replace({query: {...this.searchForm}});
			
			// this.getList();
		},
		getList() {
			let _this = this;
			// let params={...this.searchForm,type:this.type,pageSize:this.pageSize,state:1,orderBy:'sort desc,create_time',sortBy:'desc'}
			let params = {...this.searchForm, state: 1,  orderBy: 'sort '+this.sortBy+','+this.sort, sortBy: this.sortBy}
			// if(this.type=='coldBid'||this.type=='coldNeed'){
			//   params.type='coldChain';
			//   params.second_type=this.type
			// }
			
			if (this.type === 'medicine') {
				params.new_type = 'medicine';
			} else if (this.type === 'device') {
				params.new_type = 'device';
			}
			
			this.$http.httpPost({
				url: '/Bids/listPage',
				datas: params,
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;
				}
			})
		},
		toDetail(id) {
			window.open('/bids/detail/' + id, '_blank')
		},
		getQrcode(id) {
			let _this = this;
			this.$http.httpPost({
				url: '/Bids/getQrcode',
				datas: {id: id},
				success(res) {
					_this.id = id;
					_this.bids.qrcode = res.qrcode;
					_this.bids.price = res.price;
					_this.bids.bis_state = res.bid_state;
					
					if (res.bid_state === 1) {
						_this.$confirm('你可以直接查看该信息，是否立即查看?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'success'
						}).then(() => {
							let a = _this.$router.resolve({name: '/bids/detail/', params: {id: id}});
							window.open(a.href, '_blank');
							
						}).catch(() => {
							_this.bids.qrcode = null;
							_this.bids.price = 0;
							_this.bids.bid_state = 2;
							
							_this.cleanTime();
						});
					} else {
						_this.showQrcode = true;
						
						_this.timeDown();
					}
				}
			})
		}
	},
	destroyed() {
		this.cleanTime();
	}
}
</script>
<style scoped>
	.sort{
		cursor: pointer;
	}
	.sort i{
		width:10px;
	}
	.sort .on{
		color:#2376ff ;
	}
</style>