<template>
    <m-main-layout>
        <bread></bread>
        <div class="margin-auto" style="width: 600px;">
            <div class="margin-l-r-50 margin-top-50">
                <div class="box-shadow bg-light cl border-radius-5 padding">
<!--                    <img src="/images/icon_password.png" class="z"/>-->
                    <i class="el-icon-mobile-phone z f30 blue"></i>
                    <input type="text" class="f16 margin-left" v-model="formData.mobile" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入账户名(手机号)"/>
                </div>
<!--                <div class="margin-top-20 box-shadow bg-light cl border-radius-5 padding">-->
<!--                    <img src="/images/icon_password.png" class="z"/>-->
<!--                    <input type="text" class="margin-left" v-model="formData.new_pass" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请输入新密码"/>-->
<!--                </div>-->
<!--                <div class="margin-top-20 box-shadow bg-light cl border-radius-5 padding">-->
<!--&lt;!&ndash;                    <img src="/images/icon_password.png" class="z"/>&ndash;&gt;-->
<!--                    <i class="el-icon-lock z f30 blue"></i>-->
<!--                    <input type="text" class="margin-left" v-model="formData.code" style="border:0;height: 30px;line-height: 30px;width: 300px;" placeholder="请确认新密码"/>-->
<!--                </div>-->
                <div class="margin-top-20 box-shadow bg-light cl border-radius-5 padding">
                    <i class="el-icon-lock z f30 blue"></i>
                    <input type="text" class="f16 margin-left" v-model="formData.code" style="border:0;height: 30px;line-height: 30px;width: 300px;" maxlength="6" placeholder="请正确输入验证码"/>
                    <el-button :disabled="disSms" class="y border-radius-10" type="warning" size="medium" style="height: 30px;line-height: 30px;padding:0 20px" @click="sendCode">{{codeText}}</el-button>
                </div>
                <div class="margin-top-50 padding-bottom-30 dialog-footer">
                    <el-button type="primary" class="block" @click="toCheck">下一步</el-button>
                </div>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "findPass",
    data(){
        return{
            formData:{
                mobile:'',
                code:'',
            },
            disSms:false,
            codeText:'发送验证码',
            time:60,
            timeId:null,
        }
    },
    created() {

    },
    mounted() {
    },
    methods:{
        toCheck(){
            let _this = this;
            this.$http.httpPost({
                url: '/Common/checkSms',
                datas: this.formData,
                success: (res) => {
                    if(res.spm){
                        _this.$router.push({path:'/findpass2',query:{spm:res.spm,mobile:_this.formData.mobile}});
                    }
                }
            });
        },
        sendCode() {
            let _this = this;
            this.$http.httpPost({
                url: '/Common/sendSms',
                datas: this.formData,
                success: (res) => {
                    if(res === 'ok') {
                        _this.disSms = true;
                        _this.startTime();
                        _this.$utils.success('验证码发送成功');
                    }
                    // console.log(res);
                    // _this.data = res;
                }
            });
        },
        timeDown(){
            if(this.time > 0){
                this.time -= 1;
                this.codeText = this.time + 's 重新获取'
            }else{
                this.disSms = false;
                clearInterval(this.timeId);
                this.timeId = null;
                this.codeText = '发送验证码'
            }
        },
        startTime(){
            this.timeId = setInterval(this.timeDown,1000);
        },
    },
    destroyed() {
        if(this.timeId){
            clearInterval(this.timeId);
            this.timeId = null
        }
    }
}
</script>
