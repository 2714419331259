<template>
    <m-main-layout>
        <div class="cl margin-top-15">
<!--            <div class="z border-w-1 hide border-dark-6" style="width: 198px;height: 344px;padding:10px 20px !important;">-->
<!--                <div class="cl">-->
<!--                    <div class="z red f18 f-sy" style="margin-top: 3px;">招标推荐</div>-->
<!--                    <div class="y dark-2 f14 f-sy hand" style="margin-top: 6px;" @click="$router.push({path:'/tjbids'})">更多>></div>-->
<!--                </div>-->
<!--                <div class="cl"></div>-->
<!--                <zbscroll></zbscroll>-->
<!--            </div>-->
	        <div class="z" style="width:198px;height: 344px;">
		        <img src="/images/zhaohuozhu.jpg" style="width: 100%;" class="hand" @click="$router.push({path:'/company/list/hz'})"/>
		        <img src="/images/zhaowuliu.jpg" style="width: 100%;" class="margin-top hand" @click="$router.push({path:'/company/list/wuliu'})"/>
		        <img src="/images/zhaoshebei.jpg" style="width: 100%;" class="margin-top hand" @click="$router.push({path:'/company/list/shebei'})"/>
	        </div>
            <div class="y">
                <banner akey="index_banner" width="995px" height="344px"></banner>
            </div>
        </div>
	    <el-row class="margin-top margin-bottom zhibiao" :gutter="10">
			<el-col :span="3.5">
			    <div class="padding hide bg-yellow-light-line">
				    <div class="dark-2">招标公告总数量</div>
				    <number-unit fontClass="f26 margin-top orange" :value="total.bids" last="条"></number-unit>
			    </div>
		    </el-col>
			<el-col :span="3.5">
			    <div class="padding hide bg-yellow-light-line">
				    <div class="dark-2">招标公告同比增长</div>
				    <number-unit fontClass="f26 margin-top orange" :value="(total.avgInc * 100).toFixed(1)" last="%"></number-unit>
			    </div>
		    </el-col>
		    
		    <el-col :span="3.5">
			    <div class="padding hide bg-yellow-light-line">
				    <div class="dark-2">标的金额总计</div>
				    <number-unit fontClass="f26 margin-top orange" :value="total.bids_price" last="元"></number-unit>
			    </div>
		    </el-col>
		    <el-col :span="3.5">
			    <div class="padding hide bg-yellow-light-line">
				    <div class="dark-2">招标货运总量</div>
				    <number-unit fontClass="f26 margin-top orange" :value="total.hyzl" last="吨"></number-unit>
			    </div>
		    </el-col>
		    <el-col :span="3.5">
			    <div class="padding hide bg-blue-light-line">
				    <div class="dark-2">在线企业数量</div>
				    <number-unit fontClass="f26 margin-top blue-2" :value="total.company" last="家"></number-unit>
			    </div>
		    </el-col>
		    <el-col :span="3.5">
			    <div class="padding hide bg-blue-light-line">
				    <div class="dark-2">在线车辆数量</div>
				    <number-unit fontClass="f26 margin-top blue-2" :value="total.car" last="辆"></number-unit>
			    </div>
		    </el-col>
		    <el-col :span="3.5">
			    <div class="padding hide bg-blue-light-line">
				    <div class="dark-2">在线仓库容量</div>
				    <number-unit fontClass="f26 margin-top blue-2" :value="total.cangku" last="吨"></number-unit>
			    </div>
		    </el-col>
	    </el-row>
<!--        <div class="cl" style="margin-top:14px;margin-left:-4px;margin-right:-4px;">-->
<!--            <div style="width: 14.28%;padding:0 4px;" class="z margin-bottom" v-for="(item,index) in logoList">-->
<!--                <a @click="toOutUrl(item.out_url)" style="display: block;"><img :src="item.head_img.indexOf('http')>=0 ? item.head_img : $config.assetsUrl + item.head_img" style="width: 100%;height: 70px;"/></a>-->
<!--            </div>-->
<!--        </div>-->
        <div class="cl margin-top-2">
            <div class="z box-header cl hide" style="width: 890px;height: 45px;padding-right: 3px;">
                <div class="title z f22 f-sy">招标公告</div>
                <div class="y f12 more margin-top-5"><a @click="$router.push({path:'/bids/notice'})">更多>></a></div>
            </div>
            <div class="y box-header cl padding-left hide" style="width: 310px;height: 45px;padding-right: 3px;">
                <div class="title z f22 f-sy" style="margin-left:15px;">会员之窗</div>
                <div class="y f12 more margin-top-5"><a @click="$router.push({path:'/vip'})">更多>></a></div>
            </div>
        </div>
        <div class="cl bg-blue-line" style="padding-bottom: 6px;">
            <div class="z bg-light margin-top-7 padding-20" style="width: 885px;height: 480px;">
                <div class="cl" style="padding-bottom:5px;">
                    <div class="z f18 f-sy margin-right-20" style="width: 479px;padding-left:85px;">招标名称</div>
                    <div class="z text-center f18 f-sy" style="width: 156px;">所属行业</div>
                    <div class="y text-center f18 f-sy" style="width: 130px;">发布时间</div>
                </div>
                <div class="cl padding-top-bottom red-hover" style="margin-top: 0;height: 42px;" v-for="(item,index) in noticeList">
                    <div class="z dark-2 text-right" style="width: 85px;height: 22px;">
<!--                        <span class="tag-red-2 f-sy margin-right" v-if="item.is_tuijian === 1">推荐</span>-->
<!--                        <div class="text-line-1 tag-red-3 f-sy margin-right" style="width: 75px;text-align: center;margin-top:3px;" v-if="index <= 4">{{item.industry.name}}</div>-->
	                    <div class="text-line-1 tag-red-3 f-sy margin-right" style="width: 75px;text-align: center;margin-top:3px;" v-if="item.fuwu && item.fuwu.length > 0">{{item.fuwu && item.fuwu.length > 0 ? item.fuwu[0].name : ''}}</div>
                    </div>
                    <a class="z dark-2 text-line-1 margin-right-20 hand f16" style="width: 393px;" :href="'/bids/detail/'+item.id" target="_blank" rel="opener" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}">
                        {{item.title}}
                    </a>
                    <div class="z text-center dark-2" style="width: 156px;height: 20px;">
	                    <small-tag :label="item.industry.name" color="red"></small-tag>
<!--                        <template v-if="item.show_zd === 1">-->
<!--                            <small-tag :label="item.zd.type_key === 'daili' ? '代理' : '直采'" :color="item.zd.type_key === 'daili' ? 'orange' : 'red'" v-if="item.zd"></small-tag>-->
<!--                        </template>-->
                    </div>
                    <div class="y text-center dark-2 f16" style="width: 130px;line-height: 20px;">{{$moment(item.create_time).format('MM-DD')}}</div>
                </div>
            </div>
            <div class="y margin-top-7 bg-light padding-top" style="padding-left:10px !important;width: 306px;height: 480px;">
<!--                <div class="f18 f-sy" style="padding-bottom:4px;margin-left: 35px;">金牌企业</div>-->
                <a @click="toOutUrl(item.out_url)" class="hide cl f16 block padding-top-bottom" style="line-height: 22px;height: 42px;" v-for="(item,index) in companyList" target="_blank">
                    <div class="z" style="margin-top:1px;height: 20px;width: 25px;margin-right: 10px;">
                        <img :src="item.icon_img.indexOf('http') >= 0 ? item.icon_img : $config.assetsUrl + item.icon_img" v-if="item.icon_img" style="height:20px;width:20px;" class="no-v-mid"/>
                    </div>
                    <div class="z text-line-1" style="height: 42px;">{{item.title}}</div>
                </a>
            </div>
        </div>
<!--        <div style="margin:13px 0 11px 0;">-->
<!--            <banner akey="index_banner2" width="1200px" height="181px"></banner>-->
<!--        </div>-->
	    <el-row class="margin-top" style="height: 392px;">
		    <el-col :span="8">
			    <div class="box-header cl hide padding-top-14" style="height: 45px;">
				    <div class="title z f22 f-sy">找货主</div>
				    <a class="y f12 more dark-2" @click="$router.push({path:'/company/list/hz'})">更多>></a>
			    </div>
			    <div>
				    <div class="border-blue border-w-1 cl hide border-radius-5 margin-top-15 margin-bottom margin-right-30" style="height:42px;">
					    <input class="z h30 lh30 f14 margin-5" placeholder="请输入关键字" v-model="hzkw" style="border:0;width: 260px;"/>
					    <span class="y inline-block f-sy bg-blue btn text-center hand light f12" style="width: 90px;height: 40px;line-height: 40px;border-radius: 3px;" @click="toShearchHuozhu">搜索</span>
				    </div>
				    <a  :href="'/company/detail/'+item.uid" class="cl dark-2 text-line-1 margin-right-20 margin-bottom-5 hand f16 h30 lh30" target="_blank" rel="opener" v-for="(item,index) in huozhuList">
					    <div class="text-line-1 vm" style="max-width: 340px;">{{item.company}}</div>
<!--					    <div class="z margin-left-5">→</div>-->
				    </a>
			    </div>
		    </el-col>
		    <el-col :span="8">
			    <div class="box-header cl hide padding-top-14" style="height: 45px;">
				    <div class="title z f22 f-sy">找物流</div>
				    <a class="y f12 more dark-2" @click="$router.push({path:'/company/list/wuliu'})">更多>></a>
			    </div>
			    <div>
				    <div class="border-blue border-w-1 cl hide border-radius-5 margin-top-15 margin-bottom margin-right-30" style="height:42px;">
					    <input class="z h30 lh30 f14 margin-5" placeholder="请输入关键字" v-model="wuliukw" style="border:0;width: 260px;"/>
					    <span class="y inline-block f-sy bg-blue btn text-center hand light f12" style="width: 90px;height: 40px;line-height: 40px;border-radius: 3px;" @click="toShearchWuliu">搜索</span>
				    </div>
				    <a :href="'/company/detail/'+item.uid" class="cl dark-2 text-line-1 margin-right-20 margin-bottom-5 hand f16 h30 lh30" target="_blank" rel="opener" v-for="(item,index) in wuliuList">
					    <div class="text-line-1 vm" style="max-width: 340px;">{{item.company}}</div>
<!--					    <div class="z margin-left-5">→</div>-->
				    </a>
			    </div>
		    </el-col>
		    <el-col :span="8">
			    <div class="box-header cl hide padding-top-14" style="height: 45px;">
				    <div class="title z f22 f-sy">找设备</div>
				    <a class="y f12 more dark-2" @click="$router.push({path:'/company/list/shebei'})">更多>></a>
			    </div>
			    <div>
				    <div class="border-blue border-w-1 cl hide border-radius-5 margin-top-15 margin-bottom margin-right-30" style="height:42px;">
					    <input class="z h30 lh30 f14 margin-5" placeholder="请输入关键字" v-model="shebeikw" style="border:0;width: 260px;"/>
					    <span class="y inline-block f-sy bg-blue btn text-center hand light f12" style="width: 90px;height: 40px;line-height: 40px;border-radius: 3px;" @click="toShearchShebei">搜索</span>
				    </div>
				    <a :href="'/company/detail/'+item.uid" class="cl dark-2 text-line-1 margin-right-20 margin-bottom-5 hand f16 h30 lh30" target="_blank" rel="opener" v-for="(item,index) in shebeiList">
					    <div class="text-line-1 vm" style="max-width: 340px;">{{item.company}}</div>
<!--					    <div class="z margin-left-5">→</div>-->
				    </a>
			    </div>
		    </el-col>
	    </el-row>
	    <div class="box-header cl hide padding-top-14" style="height: 45px;">
		    <div class="title z f22 f-sy">合作会员</div>
	    </div>
	    <div class="cl" style="margin-top:14px;margin-left:-4px;margin-right:-4px;">
		    <div style="width: 14.28%;padding:0 4px;" class="z margin-bottom" v-for="(item,index) in logoList">
			    <a @click="toOutUrl(item.out_url)" style="display: block;"><img :src="item.head_img.indexOf('http')>=0 ? item.head_img : $config.assetsUrl + item.head_img" style="width: 100%;height: 70px;"/></a>
		    </div>
	    </div>
<!--        <div class="cl">-->
<!--            <div class="z" style="width: 595px;">-->
<!--                <div class="box-header cl hide padding-top-14 padding-right-15" style="width: 100%;height: 45px;">-->
<!--                    <div class="title z f22 f-sy">设备招标</div>-->
<!--                    <a class="y f12 more dark-2 margin-top-5" @click="$router.push({path:'/bids/device'})">更多>></a>-->
<!--                </div>-->
<!--                <div class="bg-blue-line cl" style="height: 4px;margin-top: 1px;"></div>-->
<!--                <div class="cl" style="margin-top:16px;padding-bottom:2px;">-->
<!--                    <div class="z dark-2 f18 f-sy" style="width: 370px;margin-left:73px;">招标名称</div>-->
<!--                    <div class="y text-center dark-2 f18 f-sy" style="width: 92px;">截止时间</div>-->
<!--                </div>-->
<!--                <div class="cl red-hover" :class="index === 0 ? 'padding-top-bottom' : 'padding-bottom'" style="margin-top: 8px;" v-for="(item,index) in deviceList">-->
<!--                    <div class="z dark-2 text-center" style="width: 73px;height: 25px;">-->
<!--                        <span class="tag-red-2" v-if="item.is_tuijian === 1">推荐</span>-->
<!--                    </div>-->
<!--                    <a class="z dark-2 text-line-1 margin-right-20 hand f16" style="width: 370px;height: 25px;" :href="'/bids/detail/'+item.id" target="_blank" rel="opener" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}">{{item.title}}</a>-->
<!--                    <div class="y text-center dark-2 f16" style="width: 90px;line-height: 25px;">{{$moment(item.create_time).format('MM-DD')}}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="y" style="width: 588px">-->
<!--                <div class="cl">-->
<!--                    <div class="z" style="width: 286px">-->
<!--                        <div class="box-header cl hide padding-top-14 padding-right-0" style="height: 45px;">-->
<!--                            <div class="title z f22 f-sy">冷链专区</div>-->
<!--                            <a class="y f12 more dark-2 margin-top-5" @click="$router.push({path:'/coldChain/coldBid'})">更多>></a>-->
<!--                        </div>-->
<!--                        <div class="bg-blue-line cl" style="height: 4px;margin-top: 1px;"></div>-->
<!--                        <div style="margin-top: 18px;">-->
<!--                            <banner akey="index_banner4" width="287px" height="148px"></banner>-->
<!--&lt;!&ndash;                            <img src="/images/banner4.jpg" width="100%"/>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div style="margin-top: 16px;">-->
<!--                            <div class="padding-top-bottom" style="margin-top:3px;" v-for="(item,index) in coldBidList">-->
<!--                                <a class="inline-block text-line-1" style="height: 20px;line-height: 20px;" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}" :href="'/bids/detail/'+item.id" target="_blank" rel="opener">{{item.title}}</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="y" style="width: 287px">-->
<!--                        <div class="box-header cl hide padding-top-14 padding-right-0" style="height: 45px;">-->
<!--                            <div class="title z f22 f-sy">医药专区</div>-->
<!--                            <a class="y f12 more dark-2 margin-top-5 hand" @click="$router.push({path:'/bids/medicine'})">更多>></a>-->
<!--                        </div>-->
<!--                        <div class="bg-blue-line cl" style="height: 4px;margin-top: 1px;"></div>-->
<!--                        <div style="margin-top: 18px;">-->
<!--                            <banner akey="index_banner5" width="287px" height="148px"></banner>-->
<!--&lt;!&ndash;                            <img src="/images/banner5.jpg" width="100%"/>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div style="margin-top: 16px;">-->
<!--                            <div class="padding-top-bottom" style="margin-top:3px;" v-for="(item,index) in medicineList">-->
<!--                                <a class="inline-block text-line-1" style="height: 20px;line-height: 20px;" :href="'/bids/detail/'+item.id" target="_blank" rel="opener" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}">{{item.title}}</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="cl margin-top-11">-->
<!--            <div class="z" style="width: 604px;padding-right:20px;">-->
<!--                <banner akey="index_banner3" width="584px" height="254px"></banner>-->
<!--&lt;!&ndash;                <img src="/images/banner3.jpg" width="100%" height="254"/>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="y" style="width: 587px;">-->
<!--                <div class="cl">-->
<!--                    <div class="z" style="width: 288px">-->
<!--                        <div class="box-header cl hide padding-top-14 padding-right-5" style="height: 45px;">-->
<!--                            <div class="title z f22 f-sy">政策法规</div>-->
<!--                            <a class="y f12 more dark-2 margin-top-5" @click="$router.push({path:'/news/policy'})">更多>></a>-->
<!--                        </div>-->
<!--                        <div class="bg-blue-line cl" style="height: 4px;margin-top: 2px;"></div>-->
<!--                        <div style="margin-top: 8px;">-->
<!--                            <div class="padding-top-bottom-11" v-for="(item,index) in policyList">-->
<!--                                <a class="inline-block text-line-1" style="height: 20px;" :href="'/news/detail/'+item.id" target="_blank" rel="opener" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}">{{item.title}}</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="y" style="width: 286px">-->
<!--                        <div class="box-header cl hide padding-top-14 padding-right-0" style="height: 45px;">-->
<!--                            <div class="title z f22 f-sy">散单平台</div>-->
<!--                            <a class="y f12 more dark-2 margin-top-5 hand" @click="$router.push({path:'/news/logistics'})">更多>></a>-->
<!--                        </div>-->
<!--                        <div class="bg-blue-line cl" style="height: 4px;margin-top: 2px;"></div>-->
<!--                        <div style="margin-top: 8px;">-->
<!--                            <div class="padding-top-bottom-11" v-for="(item,index) in oddList">-->
<!--                                <a class="inline-block text-line-1" style="height: 20px;" :href="'/news/detail/'+item.id" target="_blank" rel="opener" :style="{fontWeight:item.title_style.b ? 'bold' : '',color:item.title_style.c ? item.title_style.c : '',fontStyle:item.title_style.i ? 'italic' : '',textDecoration:item.title_style.u ? 'line-through #000': ''}">{{item.title}}</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </m-main-layout>
</template>

<script>
export default {
    name: "Home",
    data(){
        return{
          noticeList:[],
          companyList:[],
          oddList:[],
          coldBidList:[],
          storageList:[],
          deviceList:[],
          policyList:[],
          logisticsList:[],
          logoList:[],
	        huozhuList:[],
	        wuliuList:[],
	        shebeiList:[],
	        medicineList:[],
	        hzkw:'',
	        wuliukw:'',
	        shebeikw:'',
	        total:{bids:0,bids_price:0,hyzl:0,company:0,car:0,cangku:0,avgInc:0},
        }
    },
    mounted() {
        this.getNoticeList();
        this.getCompanyList();
        this.getLogoList();
        this.getOddList();
        // this.getStorageList();
        this.getDeviceList();
        this.getPolicyList();
        // this.getLogisticsList();
				this.getColdBidList();
				this.getMedicineList();
				this.getDataTotal();
				this.getHuozhu();
				this.getWuliu();
				this.getShebei();
    },
    methods:{
	    toShearchHuozhu(){
		    const { href } = this.$router.resolve({
			    path: "/company/list/hz?kw="+this.hzkw,
		    });
		    window.open(href, "_blank");
	    },
	    toShearchWuliu(){
		    const { href } = this.$router.resolve({
			    path: "/company/list/wuliu?kw="+this.wuliukw,
		    });
		    window.open(href, "_blank");
	    },
	    toShearchShebei(){
		    const { href } = this.$router.resolve({
			    path: "/company/list/shebei?kw="+this.shebeikw,
		    });
		    window.open(href, "_blank");
	    },
	    getHuozhu(){
		    let _this = this;
		    this.$http.httpPost({
			    url:'/UserCompany/listTitle',
			    datas:{user_type:'hz',page:1,limit:8,state:1},
			    success(res){
				    _this.huozhuList = res;
			    }
		    })
	    },
	    getWuliu(){
		    let _this = this;
		    this.$http.httpPost({
			    url:'/UserCompany/listTitle',
			    datas:{user_type:'gys',gys_type:'wuliu',page:1,limit:8,state:1},
			    success(res){
				    _this.wuliuList = res;
			    }
		    })
	    },
	    getShebei(){
		    let _this = this;
		    this.$http.httpPost({
			    url:'/UserCompany/listTitle',
			    datas:{user_type:'gys',gys_type:'shebei',page:1,limit:8,state:1},
			    success(res){
				    _this.shebeiList = res;
			    }
		    })
	    },
	    getDataTotal(){
		    let _this = this;
		    this.$http.httpPost({
			    url:'/Common/getDataTotal',
			    datas:null,
			    success(res){
				    _this.total.bids = res.bids;
						_this.total.bids_price = parseFloat(res.bids_price);
						_this.total.hyzl = res.hyzl;
						_this.total.company = res.company;
						_this.total.car = res.car;
						_this.total.cangku = res.cangku;
						_this.total.avgInc = res.avgInc;
			    }
		    })
	    },
	    getMedicineList(){
		    let _this = this;
		    this.$http.httpPost({
			    url:'/Bids/listTitle',
			    datas:{type:'medicine',page:1,pageSize:5,state:1,orderBy:'sort desc,create_time',sortBy:'desc'},
			    success(res){
				    _this.medicineList = res.list;
			    }
		    })
	    },
	    getColdBidList(){
		    let _this = this;
		    this.$http.httpPost({
			    url:'/Bids/listTitle',
			    datas:{type:'coldChain',second_type:'coldBid',page:1,pageSize:5,state:1,orderBy:'sort desc,create_time',sortBy:'desc'},
			    success(res){
				    _this.coldBidList = res.list;
			    }
		    })
	    },
        getNoticeList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/listTitle',
                datas:{type:'notice',page:1,state:1,pageSize:10,orderBy:'sort desc,create_time',sortBy:'desc',new_type:'notice'},
                success(res){
                    _this.noticeList = res.list;
                }
            })
        },
        getCompanyList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Company/listPage',
                datas:{state:1,page:1,pageSize:11,orderBy:'sort',sortBy:'asc',type:'vip'},
                success(res){
                    _this.companyList = res.list;
                }
            })
        },
        getLogoList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Company/listPage',
                datas:{state:1,page:1,pageSize:14,orderBy:'sort asc,create_time',sortBy:'asc',type:'tuijian'},
                success(res){
                    _this.logoList = res.list;
                }
            })
        },
        getOddList(){
            let _this = this;
            this.$http.httpPost({
                url:'/News/listPage',
                datas:{type:'odd',page:1,pageSize:5,state:1,orderBy:'create_time',sortBy:'desc'},
                success(res){
                    _this.oddList = res.list;
                }
            })
        },
        getStorageList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/listTitle',
                datas:{type:'storage',page:1,pageSize:5,state:1,orderBy:'sort desc,create_time',sortBy:'desc'},
                success(res){
                    _this.storageList = res.list;
                }
            })
        },
        getDeviceList(){
            let _this = this;
            this.$http.httpPost({
                url:'/Bids/listTitle',
                datas:{type:'device',page:1,pageSize:8,state:1,orderBy:'sort desc,create_time',sortBy:'desc'},
                success(res){
                    _this.deviceList = res.list;
                }
            })
        },
        getPolicyList(){
            let _this = this;
            this.$http.httpPost({
                url:'/News/listPage',
                datas:{type:'policy',page:1,pageSize:5,state:1,orderBy:'create_time',sortBy:'desc'},
                success(res){
                    _this.policyList = res.list;
                }
            })
        },
        getLogisticsList(){
            let _this = this;
            this.$http.httpPost({
                url:'/News/listPage',
                datas:{type:'logistics',page:1,pageSize:5,state:1,orderBy:'create_time',sortBy:'desc'},
                success(res){
                    _this.logisticsList = res.list;
                }
            })
        },
        toBidDetail(id){
            window.open('/bids/detail/'+id,'_blank');
        },
        toNewsDetail(id){
            window.open('/news/detail/'+id,'_blank');
        },
        toOutUrl(out_url){
            if(out_url){
                let _url = out_url.indexOf('http') >= 0 ? out_url : 'http://'+out_url;

                window.open(_url,'_blank');
            }
        }
    }
}
</script>
<style>
.zhibiao>div{
	width:calc(100% / 7);
}
</style>
